import moment from 'moment';

const CHART_WINDOW = 10; // in seconds
const MAX_SAMPLES_PER_SECOND = 10;
const TRIM_SIZE = CHART_WINDOW * MAX_SAMPLES_PER_SECOND;
const TRIM_CHECK_SIZE = TRIM_SIZE * 2;

export default class ChartData {
	constructor(dataPoints) {
		this.dataPoints = dataPoints;
		this._data = {};
		this.dataPoints.forEach(dataPoint => {
			this._data[dataPoint] = [];
		});
		this._ciSysId = null;
		this._deviceIds = null;
	}

	isAppliedTo(ciSysId) {
		return this._ciSysId === ciSysId;
	}

	appliesTo(ciSysId, deviceIds) {
		this._ciSysId = ciSysId;
		this._deviceIds = {};
		for (let id of deviceIds) {
			this._deviceIds[id] = true;
		}
	}

	getData(dataPoint) {
		return this._data[dataPoint];
	}

	getLastData(dataPoint, defaultValue) {
		if (!this._data[dataPoint]) {
			return defaultValue;
		}
		let length = this._data[dataPoint].length;
		if (length === 0) {
			return defaultValue;
		}
		return Math.round(this._data[dataPoint][length - 1].y, 2);
	}

	setValues = (data) => {
		if (this._ciSysId && this._deviceIds) {
			const {deviceId} = data;
			if (!this._deviceIds[deviceId]) {
				return;
			}
		}
		let time = moment(data.timestamp);
		let trimDataPoints = [];
		this.dataPoints.forEach(dataPoint => {
			if (data[dataPoint] !== undefined) {
				this._data[dataPoint].push({
					x: time,
					y: data[dataPoint]
				});
				if (this._data[dataPoint].length > TRIM_CHECK_SIZE) {
					trimDataPoints.push(dataPoint);
				}
			}
		});
		if (trimDataPoints.length) {
			trimDataPoints.forEach(dataPoint => {
				this._data[dataPoint].splice(0, TRIM_CHECK_SIZE - TRIM_SIZE);
			});
		}
	}
}