import React, {Component} from 'react';
import LiveChart from '../../charts/LiveChart';
import './TurbineTVRow.scss';

const TURBINE_RPM_FIELD = 'turbineRPM';
const TURBINE_POWER_FIELD = 'powerGeneration';

export default class TurbineTVRow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			field: TURBINE_POWER_FIELD,
			lastFieldValue: 0
		};
	}

	componentDidMount() {
		const {dataMagic, ciSysId, turbineIndex} = this.props;
		const {field} = this.state;
		this._interval = setInterval(() => {
			this.setState({
				lastRPM: dataMagic.getLastValue(ciSysId, TURBINE_RPM_FIELD),
				lastFieldValue: dataMagic.getLastValue(ciSysId, field, ' kWh')
			});
		}, 500);
	}

	componentWillUnmount() {
		clearInterval(this._interval);
	}

	render() {
		const {dataMagic, ciSysId, turbineIndex} = this.props;
		if (!dataMagic) {
			return null;
		}
		const ci = dataMagic.getCI(ciSysId);
		const needsMaintenance = dataMagic.isMaintenanceRequired(ciSysId);
		const {field, lastFieldValue, lastRPM} = this.state;
		return (
			<div className="component-turbine-tv-row">
				<div className={"turbine-tv-row-item turbine-overview" + (needsMaintenance ? ' needs-maintenance' : '')}>
					<img src={`/turbines/turbine-${turbineIndex}.png`} />
					<div className={"title-details"}>
						<h2>{ ci.name.display_value }</h2>
						<h3>{ ci.model_id.display_value }</h3>
					</div>
					<div className="maintenance-banner">
						<span>Maintenance</span>
					</div>
					<div className={'title-data'}>
						<h2>RPMs</h2>
						<span className="real-time-value">{lastRPM}</span>
					</div>
				</div>
				<div className="turbine-tv-row-item turbine-chart">
					<LiveChart version={2} themeColor="blue" title={'Power Generation'} maxValue={825} lastValue={ lastFieldValue } chartData={ dataMagic.getChartData(ciSysId, field) } />
				</div>
			</div>
		)
	}
}
