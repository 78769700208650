import React, {Component} from 'react';

import './LeftNav.scss';

export default class LeftNav extends Component {
	render() {
		const {data, controls, viewConfig} = this.props;
		if (!data) {
			return null;
		}
		const {ci} = data;
		return (
			<nav className="left-nav">
				<h1>IoT Bridge</h1>
				{ this.renderMenu(viewConfig) }
			</nav>
		);
	}

	clickMenuItem = (item) => {
		const {controls} = this.props;
		switch (item['#click']) {
			case 'map':
				controls.goto('map');
				break;
			case 'detail':
				const {sys_id} = item;
				controls.goto('detail', sys_id.value);
				break;
		}
	}

	renderMenu(menu) {
		const level = 0;
		return (
			<ul className="main">
				{
					Object.keys(menu).map(title => {
						let menuItem = menu[title];
						return <li key={title}>
							<a className={'nav-level-' + level} onClick={() => { this.clickMenuItem(menuItem) }}>
								{title}
							</a>
							{ this.renderMenuItems(menuItem['#items']) }
						</li>
					})
				}
			</ul>
		)
	}

	renderMenuItems(menuItems, level = 1) {
		if (!menuItems) {
			return null;
		}
		return (
			<ul>
				{
					menuItems.map((menuItem, index) => {
						const {sys_id, name} = menuItem;
						return <li key={index}>
							<a className={'nav-level-' + level} onClick={() => { this.clickMenuItem(menuItem) }}>
								{ name.display_value }
							</a>
							{ this.renderMenuItems(menuItem['#items'], level + 1) }
						</li>
					})
				}
			</ul>
		);
	}

}
