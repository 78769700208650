import React from 'react';
import { Route , BrowserRouter as Router } from 'react-router-dom';
import AppSelector from './components/AppSelector/AppSelector';
import App from './App';
import SingleDevice from './SingleDevice';
import DashboardTVView from './views/tv/DashboardTVView';

const AppRouter =   (
    <Router>
        <Route exact path="/" component={AppSelector} />
        <Route path="/app/:app_name/dashboard" component={App} />
        <Route path="/app/:app_name/view/:sys_id" component={SingleDevice} />
        <Route path="/app/:appName/tv" component={DashboardTVView}  />
    </Router>
)

export default AppRouter;
