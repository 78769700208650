import React, {Component} from "react";
import './MapView.scss';
import AppleMap from '../../components/AppleMap/AppleMap';

export default class MapView extends Component {

	viewEquipment = (equipment) => {
		const {controls} = this.props;
		controls.goto('detail', equipment.sys_id);
	}

	render() {
		const {data} = this.props;
		if (!data) {
			return;
		}
		const {mapView, ci} = data;
		const {title, polygon} = mapView;
		return (
			<div className="component-map-view">
				<header className="app-component-header">
					<h2>
						{title}
					</h2>
				</header>
				<div className="map-area">
					<AppleMap ci={ci} polygon={polygon} viewEquipment={this.viewEquipment}></AppleMap>
				</div>
			</div>
		);
	}
}
