import React, {Component} from 'react';
import "./PayloadViewer.scss";
import moment from 'moment';

export default class PayloadViewer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			payloads: []
		};
		this.client = this.props.client;
		this.isDead = true;
		this.unregister = this.client.onData((data) => {
			if (this.isDead) {
				return;
			}
			const {payloads} = this.state;
			let newPayloads = payloads.concat([]);

			// hide sensitive information
			delete data['authKey'];
			newPayloads.unshift(data);
			while (newPayloads.length > 10) {
				newPayloads.pop();
			}
			this.setState({
				payloads: newPayloads
			});
		});
		// moment.relativeTimeThreshold('s', 60);
		// moment.relativeTimeThreshold('ss', 0);
	}

	componentWillMount() {
		this.isDead = false;
	}

	componentWillUnmount() {
		this.isDead = true;
		this.unregister();
	}

	render() {
		const {payloads} = this.state;
		return (
			<div className="component-payload-viewer">
				<ul className="payloads">
					{
						payloads.length === 0 && (
							<li key="empty-state" className="payload">
								<section>
									<dl>
										<dd>No device payloads</dd>
									</dl>
								</section>
							</li>
						)
					}
				{
					payloads.map(payload => {
						return (
							<li key={payload.ts} className="payload">
								<pre>
									{ JSON.stringify(payload, null, '  ') }
								</pre>
								<footer>
									<span className="icon"></span>{ moment(payload.ts).format('HH:mm:ss') }
								</footer>
							</li>
						)
					})
				}
				</ul>
			</div>
		)
	}
}
