import 'chartjs-plugin-streaming';

window.Chart.defaults.global.animation.duration = 400;
window.Chart.defaults.global.legend.display = false;
window.Chart.defaults.global.defaultFontColor = 'white';

export function getChartOptions(theme, maxValue, version) {
	return {
		responsive: true,
		maintainAspectRatio: version === 2 ? false : true,

		// custom plugin configuration
		chartArea: {
			backgroundColor: theme.chartBackgroundColor,
		},
		scales: {
			yAxes: [{
				gridLines: {
					color: theme.gridLinesColor,
				},
				ticks: {
					beginAtZero: true,
					stepSize: maxValue ? maxValue / 8 : 5,
					fontColor: theme.fontColor || theme.gridLinesColor,
					//min: Math.min.apply(this, data_array) - 5,
					max: maxValue
				}
			}],
			xAxes: [
				{
					type: 'realtime',
					position: 'bottom',
					time: {
						displayFormats: {
							second: "h:mm:ss",
							// millisecond: "h:mm:ss"
						}
					},
					realtime: {
						delay: 3000,
					},
					gridLines: {
						color: theme.gridLinesColor,
					},
					ticks: {
						fontColor: theme.gridLinesColor
					}
				}
			]
		}
	};
}
