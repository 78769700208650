import Paho from 'paho-mqtt';

function makeid() {
	var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (var i = 0; i < 5; i++)
		text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
}

const SSL_PORT = 443;

export default class MqttClient {
	constructor(host, port = 8080, userName, password, logMessages = false) {
		this._onData = [];
		this._filterDeviceIds = [];
		this._filtering = true;

		let clientId = makeid();
		let topic = 'sniothub/rules/valid_message';

		let client = new Paho.Client(host, port, clientId);

		let onMessageArrived = (message) => {
			let payload = JSON.parse(message.payloadString);
			if (logMessages === true || window.LOG_MESSAGES === true) {
				console.log(payload);
			}
			if (this._filtering && this._filterDeviceIds.indexOf(payload.deviceId) === -1) {
				return;
			}
			this._onData.forEach(listener => listener(payload));
		};

		client.onConnectionLost = onConnectionLost;
		client.onMessageArrived = onMessageArrived;
		client.onConnected = onConnected;

		let options = {
			onSuccess: onConnect,
			onFailure: function(error) {
				console.log('Error while connecting', error);
			},
			useSSL: port === SSL_PORT,
			reconnect: true
			//mqttVersion: 3
		};
		if (userName && password) {
			Object.assign(options, {
				userName,
				password,
			});
		}

		client.connect(options);

		function onConnect() {
			console.log("onConnect");
			client.subscribe(topic);
			return;
		}

		function onConnected(reconnect, uri) {
			console.log("Client Has now connected: [Reconnected: " + reconnect + ", URI: " + uri + "]");
		}

		function onConnectionLost(responseObject) {
			if (responseObject.errorCode !== 0) {
				console.log("onConnectionLost:"+responseObject.errorMessage);
			}
		}
	}

	/**
	 * Specify a device to care about for the messages to avoid listening to everytihng
	 * @param {String} deviceId
	 */
	setDeviceIdFilter(deviceId) {
		this._filterDeviceId = deviceId;
	}

	setActiveDeviceIds(ids) {
		this._filterDeviceIds = ids;
	}

	setFiltering(state) {
		this._filtering = state;
	}

	onData(listener) {
		this._onData.push(listener);

		// return deregistration function
		return () => {
			let index = -1;
			this._onData.find((l, i) => {
				index = i;
				return l === listener;
			});
			if (index !== -1) {
				this._onData.splice(index, 1);
			}
		};
	}
}
