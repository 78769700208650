import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './AppleMap.scss';
import {getImageHref} from '../../instanceUtils';


//TODO: THIS WILL EXPIRE ON JULY 16, 2020
const MAP_JWT = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlVMNTQyQTU4MkoifQ.eyJpc3MiOiJOVjg1SFoyNTQzIiwiaWF0IjoxNTYzMzE1MzMxLjQ2MDAwMywiZXhwIjoxNzQzMzE1MzMxLjQ2MDAwM30.Mteod_dBg2QKfe10OCURNGGmXEyKt0y328mphYumVbmUiWZZoh2rivqfYdp-zUkUDvmE3PbFTqBv_8PB5UPYPw';
window.mapkit.init({
	authorizationCallback: function(done) {
		done(MAP_JWT);
	}
});

export default class AppleMap extends Component {

	constructor(props) {
		super(props);
		this.mapRef = React.createRef();
		this.map = null;
		this.mapkit = window.mapkit;
		this.state = {
			isFirstPaint: true
		};
	}

	componentDidMount() {
		this.initializeMap();
	}

	UNSAFE_componentWillReceiveProps(nextProps, currentProps) {
		const { workOrders } = nextProps;
		if (workOrders !== currentProps.workOrders) {
			this.updateAnnotations(workOrders);
		}
	}

	hasLocation = (ci) => {
		return ci['location.latitude'] && ci['location.longitude'];
	}

	initializeMap() {
		this.map = new this.mapkit.Map(this.mapRef.current);
		this.map.colorScheme = this.props.colorScheme || 'light';
		this.map.showsMapTypeControl = false;

		this.initializePolygon();
		this.initializeCIs();
	}

	initializePolygon() {
		const {map, mapkit} = this;
		const {polygon} = this.props;
		if (!polygon || !polygon.length) {
			return;
		}
		let points = polygon.map(function(point) {
			return new mapkit.Coordinate(point[0], point[1]);
		});
		let style = new mapkit.Style({
			fillColor: "#9ACD32",
			fillOpacity: .2,
			strokeColor: "#9ACD32",
			strokeOpacity: 1,
			lineWidth: 2,
			lineJoin: "round",
			lineDash: [2, 2, 6, 2, 6, 2]
		});
		let rectangle = new mapkit.PolygonOverlay(
			[points],
			{
				style: style
			}
		);
		let overlay = map.addOverlay(rectangle);
	}

	initializeCIs() {
		const {map, mapkit} = this;
		const {ci} = this.props;
		if (ci) {
			let landmarks = [];
			ci.forEach(ci => {
				if (this.hasLocation(ci)) {
					const coordinate = new mapkit.Coordinate(
						Number(ci['location.latitude'].value),
						Number(ci['location.longitude'].value)
					);
					landmarks.push({
						coordinate,
						title: ci.name.display_value,
						equipment: ci
					});
				}
			});
			this.initializeAnnotations(landmarks);
		}
	}

	initializeAnnotations(landmarks) {
		const {map, mapkit} = this;

		// Landmark annotation callout delegate
		let landmarkAnnotationCallout = {
			calloutElementForAnnotation: this.createCalloutElementForAnnotation,
			calloutAnchorOffsetForAnnotation: function(annotation, element) {
				return new DOMPoint(-148, -element.height + 30);
			},
			calloutAppearanceAnimationForAnnotation: function(annotation) {
				return 'scale-and-fadein .4s 0 1 normal cubic-bezier(0.4, 0, 0, 1.5)';
			}
		};

		let annotations = [];
		landmarks.forEach(function(landmark) {
			//let annotation = new mapkit.MarkerAnnotation(landmark.coordinate);
			let options = {};

			if (landmark.equipment.category.value == 'Wheel Tractor') {
				options = {url: {1: '/tractor.svg'}};
			} else {
				options = {url: {1: '/annotation.png'}};
			}

			let annotation = new mapkit.ImageAnnotation(landmark.coordinate, options);
			annotation.glyphColor = 'white';
			annotation.glyphText = '✢';
			annotation.title = landmark.title;

			let isDefault = false;
			annotation.selected = isDefault;
			annotation.callout = isDefault ? null : landmarkAnnotationCallout;
			annotation.callout = landmarkAnnotationCallout;
			annotation.landmark = landmark;
			annotations.push(annotation);
		});
		this.map.addAnnotations(annotations);
		this.setState({
			annotations,
		});
	}

	componentDidUpdate() {
		const { isFirstPaint, annotations } = this.state;
		if (isFirstPaint && annotations) {
			const { map } = this;
			try {
				let items = [];
				let paddingTop = 50;
				if (map.overlays.length > 0) {
					items.push(...map.overlays);
					paddingTop = 20;
				}
				items.push(...map.annotations);
				map.showItems(items, {
					padding: new this.mapkit.Padding(paddingTop, 20, 20, 20)
				});
			} catch (e) {
				console.error('Show items failed', e);
			}
			this.setState({
				isFirstPaint: false
			});
		}
	}

	updateAnnotations(workOrders) {
		if (!this.map) {
			return;
		}
		for (let annotation of this.map.annotations) {
			const equipment = annotation.landmark.equipment;
			const { sys_id, table } = equipment;

			// TODO: fix for the tractor demo?
			let imageUrl = '/annotation.png';
			if (this.findWorkOrder(sys_id, workOrders)) {
				imageUrl = '/annotation-alert.png';
			}
			annotation.url = {1: imageUrl};
		}
	}

	findWorkOrder(sysId, workOrders) {
		for (const workOrder of workOrders) {
			if (workOrder.cmdb_ci && workOrder.cmdb_ci.value === sysId) {
				return workOrder;
			}
		}
		return null;
	}

	createCalloutElementForAnnotation = (annotation) => {
		const {viewEquipment} = this.props;
		const {equipment} = annotation.landmark;
		console.log('Rendering callout for equipment', equipment);
		const {turbine_image, tractor_image} = equipment;
		const img = getImageHref(turbine_image || tractor_image);
		const el = (
			<React.Fragment>
				{ img && <img className="equipment-image" src={ img } /> }
				<h1>{ equipment.name.display_value }</h1>
				<section>
					{ equipment.model_number && <p>Model: { equipment.model_number.display_value }</p> }
					{ equipment.manufacturer && <p>Manf: { equipment.manufacturer.display_value }</p> }
					{ equipment.status && <p>Status: { equipment.status }</p> }
					<p className="lat-lon">Loc: { equipment['location.latitude'].display_value }, { equipment['location.longitude'].display_value }</p>
					<p className="link">
						<a href="#" onClick={(e) => {
							e.preventDefault();
							viewEquipment(equipment);
						}}>View Digital Twin</a>
					</p>
				</section>
			</React.Fragment>
		);
		let containerEl = document.createElement('div');
		containerEl.className = 'landmark';
		ReactDOM.render(el, containerEl);
		return containerEl;
	}

	render() {
		return (
			<div className="component-apple-map" ref={this.mapRef}></div>
		);
	}
}
