import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.scss';
import MqttClient from './services/MqttClient';
import DataFetcher from './services/DataFetcher';
import MapView from './views/map/MapView';
import DetailView from './views/detail/DetailView';
import ChartData from './charts/ChartData';
import LeftNav from './components/LeftNav/LeftNav';
import AppSelector from './components/AppSelector/AppSelector';
import {setInstanceHost, getBaseHref} from './instanceUtils';

function isEnvValid(value, isNotEmpty) {
	let isSet = value && !value.match(/^REPLACE.*/);
	if (isNotEmpty) {
		return isSet && value !== '';
	}
	return isSet;
}

// used to access the device information
const DEFAULT_SERVICENOW_HOST = 'localhost:8088';
const ENV_SERVICENOW_HOST = 'REPLACE__PUBLIC_SERVICENOW_HOST_NAME__REPLACE';
const SERVICENOW_HOST = isEnvValid(ENV_SERVICENOW_HOST) ? ENV_SERVICENOW_HOST : DEFAULT_SERVICENOW_HOST;
const USE_SECURE = SERVICENOW_HOST.indexOf('service-now.com') !== -1;

// MQTT configuration
// k19 IoT Bridge
const K19_HUB = '40.112.220.184';
const ENV_MQTT_HOST = 'REPLACE__PUBLIC_MQTT_HOST_NAME__REPLACE';
const MQTT_HOST = isEnvValid(ENV_MQTT_HOST) ? ENV_MQTT_HOST : K19_HUB;
const ENV_MQTT_PORT = 'REPLACE__PUBLIC_MQTT_PORT__REPLACE';
const MQTT_PORT = isEnvValid(ENV_MQTT_PORT) ? parseInt(ENV_MQTT_PORT, 10) : 8080;

// MQTT auth
const ENV_MQTT_USERNAME = 'REPLACE__PUBLIC_MQTT_USERNAME__REPLACE';
const MQTT_USERNAME = isEnvValid(ENV_MQTT_USERNAME, true) ? ENV_MQTT_USERNAME : null;
const ENV_MQTT_PASSWORD = 'REPLACE__PUBLIC_MQTT_PASSWORD__REPLACE';
const MQTT_PASSWORD = isEnvValid(ENV_MQTT_PASSWORD, true) ? ENV_MQTT_PASSWORD : null;
const REFRESH_INTERVAL = 6000;


let DATA_POINTS = null;
let APP_SCOPE = null;
const WHIRLWIND_APP_SCOPE = 'sn_iot_whirlwind';
const WHIRLWIND_DATA_POINTS = [
	'motorTemperature',
	'ambientTemperature',
	'turbineRPM',
	'windDirection',
	'windSpeed',
	'powerGeneration'
];
const TRACTOR_APP_SCOPE = 'sn_iot_tractor';
const TRACTOR_DATA_POINTS = [
	'temperature',
	'rpm',
	'speed'
];

class App extends Component {

    constructor(props) {
		setInstanceHost(SERVICENOW_HOST, USE_SECURE);

        super(props);
        this.state = {
            data: null,
            openRecords: [],
			activeApp: null
        };
    }

    componentDidMount() {
		let {params} = this.props.match;
		let appName = params.app_name;
		if (appName) {
			this.setApp(appName);
		}
	}

    initializeServices(appScope) {
		let baseHref = getBaseHref();
		this.fetcher = new DataFetcher(baseHref, appScope, REFRESH_INTERVAL);
		this.fetcher.onUpdate(data => {
			this.setState({
				data,
				dataPoints: data.dataPoints
			});
		});
		this.fetcher.initializeFetch();
		this.client = new MqttClient(MQTT_HOST, MQTT_PORT, MQTT_USERNAME, MQTT_PASSWORD);
		this.client.onData(data => {
			if (this.chartData) {
				this.chartData.setValues(data);
			}
		});

	}

    setApp(app) {
    	let appScope = null;
    	let dataPoints = null;
    	switch (app) {
			default:
				app = 'whirlwind';
			case 'whirlwind':
				dataPoints = WHIRLWIND_DATA_POINTS;
				appScope = WHIRLWIND_APP_SCOPE;
				break;
			case 'tractor':
				dataPoints = TRACTOR_DATA_POINTS;
				appScope = TRACTOR_APP_SCOPE;
				break;
		}
		APP_SCOPE = appScope;
		DATA_POINTS = dataPoints;
		this.initializeServices(appScope);
		this.setState({
			activeApp: app,
			appScope,
			dataPoints
		});
	}

	setActiveDevices(ciSysId, devices) {
    	let ids = devices.map(device => device.device_id.value);
    	this.ciSysId = ciSysId;
    	this.ciDeviceIds = ids;
    	this.client.setActiveDeviceIds(ids);
    	const {dataPoints} = this.state;
		this.chartData = new ChartData(DATA_POINTS);
	}

	getCI(ciSysId) {
		const {data} = this.state;
		if (!data) {
			return null;
		}
		return data.ci.find(record => {
			return record.sys_id === ciSysId;
		});
	}

	getDevicesForCI(ciSysId) {
    	let ci = this.getCI(ciSysId);
    	if (!ci) {
    		return [];
		}
    	return ci.devices;
	}

	openRecord = (ciSysId) => {
		console.log('Opening record', ciSysId);
		let devices = this.getDevicesForCI(ciSysId);
		if (devices) {
			this.setActiveDevices(ciSysId, devices);
		}
		const {openRecords} = this.state;
		let foundIndex = -1;
		openRecords.find((openRecord, index) => {
			if (openRecord.sys_id === ciSysId) {
				foundIndex = index;
			}
			return openRecord.sys_id === ciSysId;
		});
		let newRecords = openRecords.concat([]);
		if (foundIndex !== -1) {
			newRecords.splice(foundIndex, 1);
		}
		newRecords.push({
			sys_id: ciSysId,
			device_sys_ids: devices,
			ci: this.getCI(ciSysId)
		});
		this.setState({
			openRecords: newRecords,
			activeRecord: ciSysId
		});
		this.delayClose();
	}

	delayClose() {
    	setTimeout(() => {
			const {openRecords, activeSysId} = this.state;
			let newRecords = openRecords.concat([]);
			if (newRecords.length > 1 && newRecords[0].sys_id !== activeSysId) {
				newRecords.shift();
			}
			this.setState({
				openRecords: newRecords
			});
		}, 800);
	}

	closeRecords = () => {
		this.setActiveDevices(null, []);
    	this.setState({
			openRecords: []
		});
	}

    get controls() {
        if (!this._controls) {
			this._controls = {
				back: () => {
					this.closeRecords();
				},
                goto: (view, ciSysId) => {
					if (ciSysId) {
						this.openRecord(ciSysId);
					} else {
						this.closeRecords();
					}
				}
			}
        }
        return this._controls;
    }

    render() {

        const {data, ciSysId, openRecords, activeRecord} = this.state;
        if (!data) {
			return null;
		}
		const {controls} = this;
        const {navigatorView, mapView, detailView} = data;

		// main layout
        return (
            <div className="app-component">
				<div className="left">
					<LeftNav viewConfig={navigatorView} data={data} controls={this.controls} />;
				</div>
				<div className="right">
					<MapView viewConfig={mapView} controls={controls} data={data} />
					<TransitionGroup component={null}>
					{
						openRecords.map((openRecord, index) => {
								return (
									<CSSTransition key={openRecord.sys_id} in={index === 0} timeout={ 700 } classNames={ 'fade state-animate' }>
										<DetailView key={openRecord.sys_id} viewConfig={detailView} client={this.client} controls={controls}
													ci={openRecord.ci}
													ciSysId={openRecord.sys_id} ciDeviceIds={openRecord.device_sys_ids} chartData={this.chartData}
													data={data} showBackText={true}/>
									</CSSTransition>
								);
						})
					}
					</TransitionGroup>
				</div>
            </div>
        );
    }

    renderLeftNav() {
    	const {data} = this.state;
    	if (!data) {
    		return null;
		}
		return
	}
}

export default App;
