import React, {Component} from 'react';
import 'chartjs-plugin-streaming';
import {getChartOptions} from './ChartConfig';
import './LiveChart.scss';

const THEME_COLORS = {
	green: {
		chartBackgroundColor: '#D7FCD6',
		gridLinesColor: '#357C27',
		dataBackgroundColor: 'rgba(130, 176, 127, .7)',
		dataBorderColor: '#357C27'
	},
	blue: {
		chartBackgroundColor: 'rgb(0, 38, 44)',
		fontColor: '#cfcfcf',
		gridLinesColor: 'transparent',
		dataBackgroundColor: 'rgba(26, 73, 107, .9)',
		dataBorderColor: 'rgb(95, 148, 224)'
	},
	orange: {
		chartBackgroundColor: '#FCD2A2',
		gridLinesColor: '#DBA756',
		dataBackgroundColor: 'rgba(228, 188, 124, .7)',
		dataBorderColor: '#DBA756'
	}
};

window.Chart.pluginService.register({
	beforeDraw: function (chart, easing) {
		if (true || chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
			let ctx = chart.chart.ctx;
			let chartArea = chart.chartArea;
			ctx.save();
			ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
			ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
			ctx.restore();
		}
	}
});

export default class LiveChart extends Component {

	constructor(props) {
		super(props);
		this.state = {
			title: props.title,
			height: props.height || 100
		};
		this.title = props.title;
		this.chartRef = React.createRef();
		this.chart = null;
		this.chartData = props.chartData;
		this.themeColor = props.themeColor || 'green';
	}

	componentDidMount() {
		this.initializeChart();
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}

	getChartContext() {
		let ctx = this.chartRef.current.getContext('2d');
		return ctx;
	}

	initializeChart() {
		if (this.chart || !this.chartData) {
			return;
		}
		const {maxValue} = this.props;
		const theme = THEME_COLORS[this.themeColor];
		let options = getChartOptions(theme, maxValue, this.props.version);
		const chartContext = this.getChartContext();
		options.scales.yAxes[0].ticks.callback = function(value, index, values) { return (index % 2 === 0 ? value : '') };
		this.chart = new window.Chart(chartContext, {
			type: 'line',
			data: {
				datasets: [{
					label: this.state.title,
					borderColor: theme.dataBorderColor, //'rgb(52, 152, 219)',
					backgroundColor: theme.dataBackgroundColor, //'rgba(52, 152, 219, 0.3)',
					data: this.chartData,
					borderWidth: 1
				}]
			},
			options: options
		});



	}

	render() {
		const {lastValue} = this.props;
		const {height, title} = this.state;
		if (this.props.version == 2) {
			return (
				<div className={'component-live-chart chart-content theme-' + this.themeColor}>
					<h2>{title}</h2>
					<span className="real-time-value">{lastValue}</span>
					<div className="chart-content">
						<canvas ref={this.chartRef} height={height}></canvas>
					</div>
				</div>
			);
		}
		return (
			<div className={'component-live-chart theme-' + this.themeColor}>
				<h2>{title}</h2>
				<span className="real-time-value">{lastValue}</span>
				<canvas ref={this.chartRef} height={height}></canvas>
			</div>
		);
	}
}
