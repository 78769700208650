import React, {Component} from 'react';
import ChartData from '../../charts/ChartData';
import LiveChart from '../../charts/LiveChart';
import './TurbinePowerChart.scss';

export default class TurbinePowerChart extends Component {

	constructor(props) {
		super(props);
		this.state = {
			lastValue: null
		};
	}
	componentDidMount() {
		this.field = 'powerGeneration';
		this.powerData = new ChartData([this.field]);
		const {dataMagic, cis} = this.props;
		setInterval(() => {
			let value = 0;
			cis.forEach(ciSysId => {
				value += parseInt(dataMagic.getLastValue(ciSysId, this.field), 10);
			});
			//let averageValue = (value / cis.length);
			this.powerData.setValues({
				[this.field]: value
			});
			this.setState({
				lastValue: value + ' kWh'
			});
		}, 1000);
	}

	getChartData() {
		return this.powerData.getData(this.field);
	}

	render() {
		if (!this.powerData) {
			return null;
		}
		const {lastValue} = this.state;
		return (
			<div className={'component-turbine-power-chart'}>
				<LiveChart version={2} height={300} themeColor="blue" title={'Fleet Production'} maxValue={825*4} lastValue={ lastValue } chartData={ this.getChartData() } />
			</div>
		);
	}
}
