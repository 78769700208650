import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class AppSelector extends Component {

	render() {
		const {select} = this.props;
		return (
			<div className="component-app-selector">
				<h1>Choose your destination</h1>
				<Link to="/app/whirlwind/dashboard"><button>Turbine</button></Link>
				<Link to="/app/whirlwind/tv"><button>Turbine TV</button></Link>
				<Link to="/app/whirlwind/view/09d9f1a887703300b4291c7df7cb0b75"><button>Single Turbine</button></Link>
				<Link to="/app/tractor/dashboard"><button>Tractor</button></Link>
			</div>
		);
	}

}