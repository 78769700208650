import React from 'react';

let instanceHost = 'localhost:8088';
let _useSecure = false;

export function setInstanceHost(host, useSecure) {
	instanceHost = host;
	_useSecure = useSecure;
}

export function getImageHref(src) {
	if (!src) {
		return null;
	}
	return getBaseHref() + (src.charAt(0) === '/' ? src.substring(1) : src);
}

export function getListLink(table, query, label) {
	return (
		<a href={ getListHref(table, query) } target="instance">{ label }</a>
	);
}

export function getListHref(table, query) {
	return getBaseHref() + table + '_list.do' + (query ? '?sysparm_query=' + encodeURIComponent(query) : '');
}

export function getRecordLink(record, label) {
	return (
		<a href={ getRecordHref(record.table, record.sys_id) } target="instance">{ label }</a>
	);
}

export function getRecordHref(table, sysId) {
	return getBaseHref() + table + '.do?sys_id=' + sysId;
}

export function getBaseHref() {
	return 'http' + (_useSecure ? 's' : '') + '://' + instanceHost + '/';
}
