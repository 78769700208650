import React, {Component} from 'react';
import LiveChart from '../../charts/LiveChart';
import PayloadViewer from '../../components/PayloadViewer/PayloadViewer';
import DeviceViewer from '../../components/DeviceViewer/DeviceViewer';
import './DetailView.scss';
import {getRecordLink, getListLink, getImageHref} from "../../instanceUtils";

export default class DetailView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			viewPayloads: false,
			openWorkOrders: [],
			currentDataPoints: {}
		};
		this._updateDataPointsInterval = setInterval(this.updateDataPoints, 1000);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const sysId = this.props.ci.sys_id;
		let openWorkOrders = nextProps.data.workOrders.filter(workOrder => {
			return workOrder.cmdb_ci.value === sysId;
		});
		this.setState({
			openWorkOrders
		});
	}

	componentWillUnmount() {
		if (this._updateDataPointsInterval) {
			clearInterval(this._updateDataPointsInterval);
			this._updateDataPointsInterval = null;
		}
	}

	updateDataPoints = () => {
		let newData = {};
		const {dataChartGroups, dataPoints} = this.props.viewConfig;
		dataPoints.forEach(dataPoint => {
			newData[dataPoint.field] = this.getDataPoint(dataPoint.field, dataPoint.unit);
		});
		dataChartGroups.forEach(dataChartGroup => {
			newData[dataChartGroup[0].field] = this.getDataPoint(dataChartGroup[0].field, dataChartGroup[0].unit);
			if (dataChartGroup[1]) {
				newData[dataChartGroup[1].field] = this.getDataPoint(dataChartGroup[1].field, dataChartGroup[0].unit);
			}
		});
		this.setState({
			currentDataPoints: newData
		});
	}

	back = () => {
		const {controls} = this.props;
		controls.back();
	}

	onClickViewPayloads = () => {
		let {viewPayloads} = this.state;
		this.setState({
			viewPayloads: !viewPayloads
		});
	}

	getDataPoint = (field, unit) => {
		return this.props.chartData.getLastData(field, 0) + (unit ? unit : '');
	}

	render() {
		const {viewConfig, chartData, client, ci, singleMode} = this.props;
		const {dataChartGroups, dataPoints} = viewConfig;
		const {viewPayloads, openWorkOrders, currentDataPoints} = this.state;
		const {turbine_image, tractor_image} = ci;
		const img = getImageHref(turbine_image || tractor_image);

		// TODO: consider generating these
		const operationalProfiles = 1;
		const maintenancePlans = 2;
		const workOrders = openWorkOrders.length;
		const maintGroup = turbine_image ? '531912ac0fb03300cbc903a2ff767e1c' : '927900a187413300b4291c7df7cb0bed';
		const imageStyles = img ? {backgroundImage: 'url(' + img + ')'} : null;
		return (
			<div className="component-detail-view detail-view component-app-child">
				<div className="detail-view-left">
					{
						!singleMode && (
							<header className="app-component-header">
								{ this.props.showBackText &&
									<h2 onClick={this.back}>
										&lt; Back to map
									</h2>
								}
								{
									!this.props.showBackText && singleMode &&
									<h2>
										{ ci.name.display_value }
									</h2>
								}

							</header>
						)
					}
					<div className="detail-data">
						<div className="detail-data-left">
							{imageStyles && (
								<div className="image" style={imageStyles}></div>
							)}
							<dl className="record-fields">
								<dt className="record-title">
									<span className="record-title">
										{ this.getRecordLink(ci, ci.name.display_value) }
									</span>
								</dt>
								{
									viewConfig.dataFields.map((dataField, index) => {
										return !ci[dataField.field] ? null : ([
											<dt key={index + 'dt'}>{ dataField.label }:</dt>,
											<dd key={index + 'dd'}>{ ci[dataField.field].display_value }</dd>
										]);
									})
								}
								<dd className="list-link">
									{ this.getListLink('sn_iot_hub_operational_profile', 'product_model=' + ci.model_id.value, `Operational Profiles: (${operationalProfiles})`) }
								</dd>
								<dd className="list-link">
									{ this.getListLink('sn_iot_smart_maint_plan', 'maint_group=' + maintGroup, `Maintenance Plans: (${maintenancePlans})`) }
								</dd>
								<dd className="list-link">
									{ this.getListLink('sn_iot_whirlwind_task', 'cmdb_ci=' + ci.sys_id, `Work Orders: (${workOrders})`) }
								</dd>
							</dl>
							{
								workOrders > 0 && (
									<div className="maintenance-button">
										{
											singleMode ?
												<a>Maintenance Required</a>
												:
												this.getListLink('sn_iot_whirlwind_task', 'cmdb_ci=' + ci.sys_id, `Maintenance Required`)
										}
									</div>
								)
							}
						</div>
						<div className="detail-data-right">
							{
								dataPoints.map(dataPoint => {
									return (
										<div key={ dataPoint.field } className="data-group">
											<label>{ dataPoint.title }</label>
											<div className="chart">
												{ this.getDataPoint(dataPoint.field, dataPoint.unit) }

											</div>
										</div>
									);
								})
							}
						</div>
					</div>
					<div className="detail-charts">
						{
							dataChartGroups.map((dataChartGroup, index) => {
								return (
									<div key={ index } className="detail-charts-group">
										<LiveChart themeColor="green" title={dataChartGroup[0].title} maxValue={dataChartGroup[0].maxValue} lastValue={ this.getDataPoint(dataChartGroup[0].field, dataChartGroup[0].unit) } chartData={ chartData.getData(dataChartGroup[0].field) } />
										{ dataChartGroup[1] && <LiveChart themeColor="orange" title={dataChartGroup[1].title} maxValue={dataChartGroup[1].maxValue} lastValue={ this.getDataPoint(dataChartGroup[1].field, dataChartGroup[1].unit) } chartData={chartData.getData(dataChartGroup[1].field)} /> }
									</div>
								);
							})
						}
					</div>
					<div className="maintenance-history">
						<h2>Maintenance History</h2>
						{  getListLink('sn_iot_whirlwind_task', 'cmdb_ci=' + ci.sys_id) }
					</div>
				</div>
				{
					!singleMode && (
						<div className="detail-view-right">
							<header className="app-component-header">
								<h2>
									Paired Devices
									{ ci.devices.length > 0 && (
										<button onClick={this.onClickViewPayloads}>
											{ viewPayloads ? 'View Devices' : 'View Payloads' }
										</button>
									)}
								</h2>
							</header>
							<div className="detail-view-right-content">
								{
									viewPayloads && <PayloadViewer client={client}></PayloadViewer>
								}
								{
									!viewPayloads && <DeviceViewer ci={ci} />
								}
							</div>
						</div>
					)
				}
			</div>
		);
	}

	getRecordLink(record, label) {
		if (this.props.singleMode) {
			return label;
		} else {
			return getRecordLink(record, label);
		}
	}

	getListLink(table, query, label) {
		if (this.props.singleMode) {
			return label;
		} else {
			return getListLink.apply(getListLink, arguments);
		}
	}
}
