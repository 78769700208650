import React, {Component} from "react";
import './DashboardTVView.scss';
import DataMagic from '../../services/DataMagic';
import AppleMap from '../../components/AppleMap/AppleMap';
import TurbineTVRow from './TurbineTVRow';
import TurbinePowerChart from './TurbinePowerChart';
import {CSSTransition} from "react-transition-group";

const TURBINE_CIS = [
	'e05931a887703300b4291c7df7cb0bea',
	'09d9f1a887703300b4291c7df7cb0b75',
	'8ed9f1a887703300b4291c7df7cb0b9a',
	'bad9f1a887703300b4291c7df7cb0b78',
];

export default class DashboardTVView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dataLoaded: false,
			dashboardReady: false,
			openWorkOrders: 0,
			workOrders: [],
		};
		this._timeout = null;
	}

	componentDidMount() {
		let {params} = this.props.match;
		this.appName = params.appName;
		this.dataMagic = new DataMagic(this.appName);
		this.dataMagic.setupServices().then(() => {
			TURBINE_CIS.forEach(ciSysId => {
				this.dataMagic.addChart(ciSysId);
			});
			this.setState({
				dataLoaded: true
			});
			this.removeLoadingOverlay();
		});

		// we want to refresh the page every 30 minutes
		const REFRESH_PAGE_INTERVAL = 30 * 60 * 1000;
		this._timeout = setTimeout(() => {
			console.log('Refreshing the page');
			window.location.reload(false);
		}, REFRESH_PAGE_INTERVAL);

		this._interval = setInterval(() => {
			const nextOpenWorkOrders = this.dataMagic.getWorkOrders().length;
			const { openWorkOrders } = this.state;
			if (openWorkOrders !== nextOpenWorkOrders) {
				this.setState({
					openWorkOrders: nextOpenWorkOrders,
					workOrders: this.dataMagic.getWorkOrders(),
				});
			}
		}, 1500);
	}

	componentWillUnmount() {
		if (this._interval) {
			clearTimeout(this._interval);
			this._interval = null;
		}
		if (this._timeout) {
			clearTimeout(this._timeout);
			this._timeout = null;
		}
	}

	removeLoadingOverlay() {
		if (!this._removedLoadingOverlay) {
			this._removedLoadingOverlay = true;
			setTimeout(() => {
				this.setState({
					dashboardReady: true
				});
			}, 1700); // delay for the map to paint...
		}
	}

	viewEquipment = (equipment) => {
		this.props.history.push('/app/' + this.appName + '/view/' + equipment.sys_id);
	}

	render() {
		const {dashboardReady, dataLoaded} = this.state;
		return (
			<div className={'component-dashboard-tv-view'}>
				{ this.renderLoading() }
				{ dataLoaded && this.renderContent() }
			</div>
		);
	}

	renderLoading() {
		const {dashboardReady} = this.state;
		return (
				<CSSTransition in={!dashboardReady} timeout={ 500 } classNames={ 'state-animate' }>
					<div className="loading-content">
						<h1 className={"loading-text animate-pulsate"}>
							Loading
						</h1>
					</div>
				</CSSTransition>
		);
	}

	renderContent() {
		const {mapView, ci} = this.dataMagic.data;
		const {title, polygon} = mapView;
		const { openWorkOrders, workOrders } = this.state;
		const unhours = 2;
		const unmins = 7;
		return (
			<React.Fragment>
				<h1> Fleet Monitoring / Acme Wind Energy Center</h1>
				<div className="tv-content">
					<div className="tv-column">
						<TurbineTVRow dataMagic={this.dataMagic} ciSysId={TURBINE_CIS[0]} turbineIndex={1} />
						<TurbineTVRow dataMagic={this.dataMagic} ciSysId={TURBINE_CIS[1]} turbineIndex={2} />
						<TurbineTVRow dataMagic={this.dataMagic} ciSysId={TURBINE_CIS[2]} turbineIndex={3} />
						<TurbineTVRow dataMagic={this.dataMagic} ciSysId={TURBINE_CIS[3]} turbineIndex={4} />
					</div>
					<div className="tv-column">
						<div className="tv-row mini-map">
							<AppleMap colorScheme={"dark"} ci={ci} polygon={polygon} workOrders={ workOrders } viewEquipment={this.viewEquipment}></AppleMap>
						</div>
						<div className="tv-row single-value-items">
							<div className="open-incident single-value-item">
								Open Work Orders
								<h2>{ openWorkOrders }</h2>
							</div>
							<div className="singleValue single-value-item">
								Unscheduled Downtime
								<h2>{unhours}h {unmins}m</h2>
							</div>
						</div>
						<div className="tv-row production-graph">
							<TurbinePowerChart cis={TURBINE_CIS} dataMagic={this.dataMagic} />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

