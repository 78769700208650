import React, {Component} from 'react';
import "./DeviceViewer.scss";
import { getRecordLink } from '../../instanceUtils';

export default class DeviceViewer extends Component {

	render() {
		const {ci} = this.props;
		const devices = ci.devices ? ci.devices : [];
		return (
			<div className="component-device-viewer">
				<ul className="devices">
				{
					devices.length === 0 && (
						<li key="empty-state" className="device">
							<section>
								<dl>
									<dd>No paired devices</dd>
								</dl>
							</section>
							<footer>
								{ getRecordLink(ci, 'Pair Devices') }
							</footer>
						</li>
					)
				}
				{
					devices.map((device, index) => {
						return <li key={index} className="device">
							<header>
								<div className="icon"></div>
								{ device.name.display_value }
							</header>
							<section>
								<dl>
									<dt>Device ID</dt>
									<dd>{ device.device_id.display_value }</dd>
									<dt>Status</dt>
									<dd>{ device.operational_status.display_value }</dd>
								</dl>
							</section>
							<footer>
								{ getRecordLink(device, 'View Device') }
							</footer>
						</li>;
					})
				}
				</ul>
			</div>
		);
	}

}
