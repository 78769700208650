import {getBaseHref, setInstanceHost} from '../instanceUtils';
import DataFetcher from './DataFetcher';
import MqttClient from './MqttClient';
import ChartData from '../charts/ChartData';

function isEnvValid(value, isNotEmpty) {
	let isSet = value && !value.match(/^REPLACE.*/);
	if (isNotEmpty) {
		return isSet && value !== '';
	}
	return isSet;
}

// used to access the device information
const DEFAULT_SERVICENOW_HOST = 'localhost:8088';
const ENV_SERVICENOW_HOST = 'REPLACE__PUBLIC_SERVICENOW_HOST_NAME__REPLACE';
const SERVICENOW_HOST = isEnvValid(ENV_SERVICENOW_HOST) ? ENV_SERVICENOW_HOST : DEFAULT_SERVICENOW_HOST;
const USE_SECURE = SERVICENOW_HOST.indexOf('service-now.com') !== -1;

// MQTT configuration
// k19 IoT Bridge
const SUBSCRIBE_USER = 'subscribe';
const IOT_DEMO_1 = 'broker.iotdemo1-dev.sniot.net';
const IOT_DEMO_1_PASSWORD = '2977b0c48e3f8360109593625cd6f51bca49a7832de1eed238b91b3a4e43e413';
const EBC_DEMO = 'broker.ebcnowxsmartops-demo.sniot.net';
const EBC_DEMO_PASSWORD = '32bcd63ace9e14f75e683cb92e7df3e7c08fdadfe798cb763deba13eee7f8c1b';
//const K19_HUB = '';

const ENV_MQTT_HOST = 'REPLACE__PUBLIC_MQTT_HOST_NAME__REPLACE';
const MQTT_HOST = isEnvValid(ENV_MQTT_HOST) ? ENV_MQTT_HOST : IOT_DEMO_1;
const ENV_MQTT_PORT = 'REPLACE__PUBLIC_MQTT_PORT__REPLACE';
const MQTT_PORT = isEnvValid(ENV_MQTT_PORT) ? parseInt(ENV_MQTT_PORT, 10) : 443; // use SSL by default

// MQTT auth
const ENV_MQTT_USERNAME = 'REPLACE__PUBLIC_MQTT_USERNAME__REPLACE';
const MQTT_USERNAME = isEnvValid(ENV_MQTT_USERNAME, true) ? ENV_MQTT_USERNAME : SUBSCRIBE_USER;
const ENV_MQTT_PASSWORD = 'REPLACE__PUBLIC_MQTT_PASSWORD__REPLACE';
const MQTT_PASSWORD = isEnvValid(ENV_MQTT_PASSWORD, true) ? ENV_MQTT_PASSWORD : IOT_DEMO_1_PASSWORD;
const REFRESH_INTERVAL = 6000;


let DATA_POINTS = null;
let APP_SCOPE = null;
const WHIRLWIND_APP_SCOPE = 'sn_iot_whirlwind';
const WHIRLWIND_DATA_POINTS = [
	'motorTemperature',
	'ambientTemperature',
	'turbineRPM',
	'windDirection',
	'windSpeed',
	'powerGeneration'
];
const TRACTOR_APP_SCOPE = 'sn_iot_tractor';
const TRACTOR_DATA_POINTS = [
	'temperature',
	'rpm',
	'speed'
];

// BECAUSE MAGIC.
export default class DataMagic {

	constructor(appName) {

		// VERY IMPORTANT!
		setInstanceHost(SERVICENOW_HOST, USE_SECURE);
		this.initialized = false;
		this.appName = appName;
	}

	setupServices() {
		let resolver;
		let setupComplete = new Promise((resolve, reject) => {
			resolver = resolve;
		});
		let baseHref = getBaseHref();
		let appScope = this.appName === 'tractor' ? TRACTOR_APP_SCOPE : WHIRLWIND_APP_SCOPE;

		// setup general device data fetcher
		this.fetcher = new DataFetcher(baseHref, appScope, 0);
		this.fetcher.onUpdate(data => {
			console.log('Received device data');
			this.data = data;
			this.dataPoints = data.detailView.dataPoints.map(dataPoint => {
				return dataPoint.field;
			});
			let morePoints = data.detailView.dataChartGroups[0].map(groupItem => {
				return groupItem.field;
			});
			this.dataPoints = this.dataPoints.concat(morePoints);
			if (!this.initialized) {
				this.initialized = true;
				resolver();
			}
		});
		this.fetcher.initializeFetch(DataFetcher.DEVICE_DATA);

		// setup fetcher for work orders
		this.workOrderFetcher = new DataFetcher(baseHref, appScope, REFRESH_INTERVAL);
		this.workOrderFetcher.initializeFetch(DataFetcher.WORK_ORDERS);
		this.workOrderFetcher.onUpdate(data => {
			console.log('Received work orders');
			this._workOrders = data.workOrders;
		});
		this.activeCharts = [];
		this.client = new MqttClient(MQTT_HOST, MQTT_PORT, MQTT_USERNAME, MQTT_PASSWORD);
		this.client.setFiltering(false);
		this.client.onData(data => {
			let {deviceId} = data;
			for (let chartData of this.activeCharts) {
				chartData.setValues(data);
			}
		});
		return setupComplete;
	}

	isMaintenanceRequired = (ciSysId) => {
		if (this._workOrders) {
			for (let workOrder of this._workOrders) {
				if (workOrder.cmdb_ci && workOrder.cmdb_ci.value === ciSysId) {
					return true;
				}
			}
		}
		return false;
	}

	addChart = (ciSysId) => {
		let devices = this.getDevicesForCI(ciSysId);
		let deviceIds = devices.map(device => {
			return device.device_id.value;
		});
		let chartData = new ChartData(this.dataPoints);
		chartData.appliesTo(ciSysId, deviceIds);
		this.activeCharts.push(
			chartData
		);
	}

	getChartData(ciSysId, field) {
		for (let chartData of this.activeCharts) {
			if (chartData.isAppliedTo(ciSysId)) {
				return chartData.getData(field);
			}
		}
	}

	getLastValue(ciSysId, field, unit) {
		for (let chartData of this.activeCharts) {
			if (chartData.isAppliedTo(ciSysId)) {
				return chartData.getLastData(field, 0) + (unit ? unit : '');
			}
		}
		return null;
	}

	removeChart(ciSysId) {
		throw 'Not Implemented';
	}

	getWorkOrders() {
		return this._workOrders || [];
	}

	getCIs() {
		return this.data.ci;
	}

	getCI(ciSysId) {
		const {data} = this;
		if (!data) {
			return null;
		}
		return data.ci.find(record => {
			return record.sys_id === ciSysId;
		});
	}

	getCIForDevice(deviceId) {
		return this.data.ci.find(ci => {
			for (let device of ci.devices) {
				if (device.device_id.value === deviceId) {
					return true;
				}
			}
		});
	}

	getDevicesForCI(ciSysId) {
		let ci = this.getCI(ciSysId);
		if (!ci) {
			return [];
		}
		return ci.devices;
	}

}
