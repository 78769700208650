
class DataFetcher {

	constructor(baseHref, appScope, refreshInterval = 20000) {
		this._baseHref = baseHref;
		this._data = {};
		this._appScope = appScope;
		this._refreshInterval = refreshInterval;
	}

	initializeFetch(mode = DataFetcher.DEVICE_DATA) {
		switch (mode) {
			case DataFetcher.DEVICE_DATA:
				this._requestUrl = this._baseHref + 'api/' + this._appScope + '/dashboard_data/device_data';
				break;
			case DataFetcher.WORK_ORDERS:
				this._requestUrl = this._baseHref + 'api/' + this._appScope + '/dashboard_data/device_data?work_orders=true';
				break;
		}
		this.fetch();
	}

	destroy() {
		if (this._interval) {
			clearTimeout(this._interval);
			this._interval = null;
		}
		this._listener = null;
	}

	_setTimeout() {
		if (this._refreshInterval > 0) {
			this._interval = setTimeout(this.fetch.bind(this), this._refreshInterval);
		}
	}

	fetch() {
		if (this._request) {
			console.error('Request in progress');
			return false;
		}
		this._request = new XMLHttpRequest();
		this._request.addEventListener('load', () => {
			let json = JSON.parse(this._request.response);
			this._request = null;
			this._parseResponseData(json);
			this._setTimeout();
		});
		this._request.open('GET', this._requestUrl);
		this._request.send();
	}

	onUpdate(listener) {
		this._listener = listener;
	}

	get data() {
		return this._data;
	}

	_parseResponseData(data) {

		// TODO: smarts about the incoming data
		//console.log('Response data: ' , data);
		this._data = data.result;
		if (this._listener) {
			this._listener(this._data);
		}
	}
}

DataFetcher.DEVICE_DATA = 'device_data';
DataFetcher.WORK_ORDERS = 'work_orders';

export default DataFetcher;
